import { ClockIcon } from "@heroicons/react/24/outline";
import { Link } from "@inertiajs/react";
import { FormattedMessage } from "react-intl";
import { unsplashCrop } from "../../utils";

export default function OpportunityCard({
    opportunity,
}: {
    opportunity: {
        slug: string;
        collaborator_name: string;
        title: string;
        type: any;
        timings: string;
        imageUrl: string;
        collaborator: {
            hasActiveLicense: boolean;
        };
        logoUrl: string;
        initials: string;
        closeAt: string;
    };
}) {
    return (
        <li className="col-span-1 bg-white overflow-hidden focus-within:outline focus-within:outline-2 focus-within:outline-black outline-offset-2 rounded-xl">
            <Link
                href={route("show-opportunity", opportunity.slug)}
                className="focus:outline-none"
            >
                <div className="pt-4 pb-3">
                    <h3 className="uppercase text-sm font-semibold text-rose-600">
                        {opportunity.collaborator_name}
                    </h3>
                    <h4 className="text-lg md:text-base lg:text-lg text-gray-900 whitespace-nowrap text-ellipsis overflow-hidden">
                        {opportunity.title}
                    </h4>
                    <p className="text-sm font-medium">
                        <FormattedMessage
                            id={opportunity.type?.title ?? opportunity.type}
                        />
                        {opportunity?.timings && (
                            <span className="ml-0.5 text-gray-500 font-normal">
                                {opportunity.timings.replaceAll("#", " • ")}
                            </span>
                        )}
                    </p>
                </div>
                <div className="relative">
                    <div className="aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-xl bg-gray-100">
                        <img
                            src={unsplashCrop(opportunity.imageUrl)}
                            alt=""
                            className="object-cover"
                        />
                    </div>
                    {opportunity.collaborator?.hasActiveLicense && (
                        <div
                            className={`absolute ${
                                opportunity.logoUrl
                                    ? "bg-white"
                                    : "bg-gradient-to-r from-voice-light to-voice"
                            } top-5 left-5 size-16 flex items-center justify-center p-2 rounded-lg border border-gray-300`}
                        >
                            {opportunity.logoUrl ? (
                                <img
                                    src={opportunity.logoUrl}
                                    alt={`Logo for ${opportunity.collaborator_name}`}
                                />
                            ) : (
                                <span className="text-2xl text-white font-semibold">
                                    {opportunity.initials}
                                </span>
                            )}
                        </div>
                    )}
                    <div className="absolute w-full bg-gray-900 opacity-90 left-0 bottom-0 rounded-b-xl px-4 py-2">
                        <div className="flex items-center text-white">
                            <span>
                                <ClockIcon className="size-4" />
                            </span>
                            <span className="text-sm ml-1">
                                <FormattedMessage
                                    id="Closes :closeDate"
                                    values={{ closeDate: opportunity.closeAt }}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </Link>
        </li>
    );
}
